import React from 'react'
import { Wrapper } from './styles'

interface Props {
  children: React.ReactNode
}

const TitleWrapper: React.FC<Props> = ({ children }) => <Wrapper>{children}</Wrapper>

export default TitleWrapper
