import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 2rem 0;
  text-align: center;
  background: ${p => p.theme.colors.secondaryBackgroundColor};

  small {
    color: ${p => p.theme.colors.white};
    ${({ theme }) => ({ ...theme.size.xs })};
    display: inline-block;

    .read-more-button {
      cursor: pointer;
      background: #fff;
      color: #0089ba;
      padding: 10px;
      border-radius: 4px;
      display: block;
      margin-top: 10px;
    }
  }
`
